// import file
//北區 中區 南區
import north_stores from './data/north_stores.js';
import center_stores from './data/center_stores.js';
import south_stores from './data/south_stores.js';
import east_stores from './data/east_stores.js';

import w1_winners from './winner/w1_winners.js';
// import w2_winners from './winner/w2_winners.js';
// import w3_winners from './winner/w3_winners.js';
// 每週import一個js  
// console.log(north_stores)
// console.log(center_stores)
// console.log(south_stores)
// console.log(east_stores)
//{ id: 'east', name: '東區', stores: east_stores }

const vue = new Vue({
    el: '#app',
    data: {
        activeItem: 'north',
        sections: [
            { id: 'north', name: '北區', stores: north_stores },
            { id: 'center', name: '中區', stores: center_stores },
            { id: 'south', name: '南區', stores: south_stores }, 
            { id: 'east', name: '東區', stores: east_stores }
               
        ],
        

    },
    methods: {
        isActive(menuItem) {
            return this.activeItem === menuItem
        },
        setActive(menuItem) {
            this.activeItem = menuItem
        }
    }
});

const vue2 = new Vue({
    el: '#appwin',
    data: {
        winners:[
        { id: 1, title: w1_winners.title, winners: w1_winners.datas} 
        ],
        //  { id: 1, title: w1_winners.title, winners: w1_winners.datas} 
        // { id: 2, title: w2_winners.title, winners: w2_winners.datas},
        // { id: 3, title: w3_winners.title, winners: w3_winners.datas}
        //使用時，複製一行物件：修改id 與 w1編號就可以
        // 每週放入一個obj  
        openItem:'得獎名單預計於12月31日公布，敬請期待！'
        // 每週放入一個obj    
        // winners: [
        // { id: 1, title: w1_winners.title, winners: w1_winners.datas },
        // { id: 2, title: w2_winners.title, winners: w2_winners.datas },
        // { id: 3, title: w3_winners.title, winners: w3_winners.datas },
        // ]
    }
});
